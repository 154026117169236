import Splide from "@splidejs/splide";

document.addEventListener('DOMContentLoaded', () => {
    const sliderElements = document.querySelectorAll('.gu__bought-courses__topics-slider')

    sliderElements.forEach(sliderElement => {
        if (!(sliderElement instanceof HTMLElement)) return

        new Splide(sliderElement, {
            focus: 0,
            omitEnd: true,
            pagination: false,
            autoWidth: true,
            gap: 'var(--bs-gutter-x)',
            padding: 'var(--gu-outer-padding)',
            mediaQuery: 'min',
            breakpoints: {
                992: {
                    padding: 0
                }
            }
        }).mount()
    })
})
