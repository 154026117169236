import onDOMContentLoaded from "../../../base/util/dom/onDOMContentLoaded"

export default class Navigation {
    public static readonly Selectors = {
        NavContainer: '.js-navigation',
        NavToggles: '.js-navigation-toggle'
    }

    private navOpen: boolean = false
    private readonly elements: { navContainerElem: HTMLElement|null, navToggleElems: NodeListOf<HTMLInputElement> }
    constructor(element: HTMLElement) {
        this.elements = {
            navContainerElem: element.querySelector(Navigation.Selectors.NavContainer),
            navToggleElems: element.querySelectorAll(Navigation.Selectors.NavToggles)
        }

        if (!(this.elements.navContainerElem instanceof HTMLElement)) {
            if (console && console.warn) {
                console.warn('1677233056 – No nav container element found, skipping event binding')
            }
            return
        }

        if (!this.elements.navToggleElems || !this.elements.navToggleElems.length) {
            if (console && console.warn) {
                console.warn('1677174672 – No nav toggle elements found, skipping event binding')
            }
            return
        }

        Array.prototype.slice.call(this.elements.navToggleElems).forEach(navToggleElem => {
            navToggleElem.addEventListener('input', this.onNavToggleInput.bind(this))
        })

        document.addEventListener('click', this.onDocumentClick.bind(this))
    }

    private onNavToggleInput(event: Event) {
        if (!event || !event.target || !(event.target instanceof HTMLInputElement)) {
            throw new Error('1677174530 – No valid nav toggle input element given!')
        }

        this.navOpen = true

        // Reset other nav toggles
        Array.prototype.slice.call(this.elements.navToggleElems).forEach(navToggleElem => {
            if (navToggleElem !== event.target) {
                navToggleElem.checked = false
            }
        })
    }

    private onDocumentClick(event: Event) {
        if (!event || !event.target) {
            throw new Error('1677233215 – No valid event given!')
        }

        if (!(event.target instanceof HTMLElement || event.target instanceof SVGElement)) {
            throw new Error('1677233215 – No valid event target element given!')
        }

        // Skip reset if event.target is not-visible input nav toggle, click event is triggered twice on label click (label and then input)
        if (Array.prototype.slice.call(this.elements.navToggleElems).includes(event.target)) {
            return;
        }

        // Detect click outside of .gu__main-nav element
        if (!event.target.closest(Navigation.Selectors.NavContainer)) {
            this.resetNavToggles()
        }
    }

    private resetNavToggles() {
        // Skip if nav is already closed
        if (!this.navOpen) return;

        Array.prototype.slice.call(this.elements.navToggleElems).forEach(navToggleElem => {
            navToggleElem.checked = false
        })

        this.navOpen = false
    }
}

onDOMContentLoaded(() => {
    const headerElem = document.querySelector('.gu__header')
    if (!(headerElem instanceof HTMLElement)) return
    new Navigation(headerElem as HTMLElement)
})
