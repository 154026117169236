interface HeaderDOM {
    header: HTMLElement
}

export default class Header {
    public static readonly Selectors = {
        Header: '.gu__header'
    }

    private static readonly States = {
        CartHasItems: 'gu__header--cart-has-items'
    }

    private readonly dom: HeaderDOM;
    public constructor(element: HTMLElement, cartItemCount: number) {
        this.dom = {
            header: element,
        }

        this.toggleHeaderStateByCartItemCount(cartItemCount)
    }

    public toggleHeaderStateByCartItemCount(cartItemCount: number) {
        this.dom.header.classList.toggle(Header.States.CartHasItems, Boolean(cartItemCount));
    }
}

document.addEventListener('DOMContentLoaded', () => {
    if (!window.AS || !window.AS.globalConfig || !window.AS.globalConfig.shop || (window.AS.globalConfig.shop.cartItems === undefined || window.AS.globalConfig.shop.cartItems === null)) return
    const cartItemCount = window.AS.globalConfig.shop.cartItems
    const header = document.querySelector(Header.Selectors.Header)
    if (!(header instanceof HTMLElement)) return
    new Header(header, cartItemCount)
});
