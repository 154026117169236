import Splide from "@splidejs/splide";

document.addEventListener('DOMContentLoaded', () => {
    const sliderElements = document.querySelectorAll('.gu__m7-splide-carousel')

    sliderElements.forEach(sliderElement => {
        if (!(sliderElement instanceof HTMLElement)) return

        const sliderSlideElements = sliderElement.querySelectorAll('.gu__m7-splide-carousel__slide')
        const showHideSliderArrows = (slidesPerPage: number) => sliderSlideElements.length > slidesPerPage

        new Splide(sliderElement, {
            pagination: false,
            perPage: 1,
            mediaQuery: 'min',
            gap: 'var(--bs-gutter-x)',
            breakpoints: {
                576: {
                    arrows: showHideSliderArrows(2),
                    perPage: 2,
                },
                992: {
                    arrows: showHideSliderArrows(3),
                    perPage: 3
                }
            }
        }).mount()
    })
})
