import {AxiosResponse} from "axios";
const axios = require('axios');

interface EventListDOM {
    loadMoreButtonContainer: Array<HTMLElement>;
    loadMoreButtons: Array<HTMLButtonElement>;
    eventList: HTMLElement | null;
}

export default class EventListing {
    public static readonly Selectors = {
        Root: ".gu__event-list",
        LoadMoreButtonContainer: '.gu__event-list__load-more-container',
        LoadMoreButtons: '.gu__event-list__load-more',
        EventList: '.gu__event-list__list',
    };

    private static readonly Attributes = {
        EndPage: "data-end-page",
        LoadMoreUri: "data-load-more-uri",
    };

    private static readonly States = {
        LoadMoreButtonContainerHidden: 'gu__event-list__load-more-container--hidden'
    };

    private readonly dom: EventListDOM;
    private loadMoreLastPage: number = 2;
    private loadMoreNextPage: number = 2;
    private loadMoreUri: string|null = null;
    public constructor(element: HTMLElement) {
        this.dom = {
            loadMoreButtonContainer: Array.prototype.slice.call(element.querySelectorAll(EventListing.Selectors.LoadMoreButtonContainer)),
            loadMoreButtons: Array.prototype.slice.call(element.querySelectorAll(EventListing.Selectors.LoadMoreButtons)),
            eventList: element.querySelector(EventListing.Selectors.EventList),
        };

        this.loadMoreUri = element.getAttribute(EventListing.Attributes.LoadMoreUri) ?? null;

        if (this.dom.loadMoreButtons.length && this.loadMoreUri) {
            this.loadMoreLastPage = Number.parseInt(element.getAttribute(EventListing.Attributes.EndPage) ?? '');
            this.dom.loadMoreButtons.forEach(loadMoreButtonElem => {
                loadMoreButtonElem.addEventListener('click', this.onLoadMoreClick.bind(this));
            });
        }
    }

    private onLoadMoreClick(event: Event) {
        if(!this.loadMoreUri || !this.dom.loadMoreButtons || !this.dom.eventList) return;
        if (!(event.target instanceof HTMLButtonElement) || event.target.getAttribute('disabled')) return;
        this.dom.loadMoreButtons.forEach(loadMoreButtonElem => loadMoreButtonElem.setAttribute('disabled', 'disabled'));

        axios.get(this.loadMoreUri, {
            params: {
                page: this.loadMoreNextPage
            }
        }).then((res: AxiosResponse) => {
            if (!this.dom.eventList) return;
            this.dom.eventList.innerHTML += res.data;
            this.loadMoreNextPage++;
            this.dom.loadMoreButtons.forEach(loadMoreButtonElem => this.loadMoreNextPage <= this.loadMoreLastPage && loadMoreButtonElem.removeAttribute('disabled'));
            this.dom.loadMoreButtonContainer.forEach(loadMoreButtonContainerElem => this.loadMoreNextPage > this.loadMoreLastPage && loadMoreButtonContainerElem.classList.add(EventListing.States.LoadMoreButtonContainerHidden));
        })
        .catch((error : AxiosResponse)=> {
            console.log(error);
        });
    }
}

document.addEventListener('DOMContentLoaded', (evt) => {
    Array.from(document.querySelectorAll(EventListing.Selectors.Root)).forEach(listElem => {
        if (!(listElem instanceof HTMLElement)) return;
        new EventListing(listElem);
    })
});
