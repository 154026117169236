const Arrow = require('./../../../../public/static/icons/Arrow.svg');
const ArrowNav = require('./../../../../public/static/icons/ArrowNav.svg');
const Arrow2 = require('./../../../../public/static/icons/Arrow2.svg');
const Avatar = require('./../../../../public/static/icons/Avatar.svg');
const Avatar_angemeldet = require('./../../../../public/static/icons/Avatar_angemeldet.svg');
const Avatar_stern = require('./../../../../public/static/icons/Avatar_stern.svg');
const Wrench = require('./../../../../public/static/icons/Wrench.svg');
const Check = require('./../../../../public/static/icons/Check.svg');
const CloseError = require('./../../../../public/static/icons/CloseError.svg');
const Download = require('./../../../../public/static/icons/Download.svg');
const Feedback = require('./../../../../public/static/icons/Feedback.svg');
const GesKurs_Hege = require('./../../../../public/static/icons/GesKurs_Hege.svg');
const GesKursJagdpraxis = require('./../../../../public/static/icons/GesKursJagdpraxis.svg');
const GesKursNaturschutz = require('./../../../../public/static/icons/GesKursNaturschutz.svg');
const GesKursRecht = require('./../../../../public/static/icons/GesKursRecht.svg');
const GesKursWaffenMunitionOptik = require('./../../../../public/static/icons/GesKursWaffenMunitionOptik.svg');
const GesKursWildbret = require('./../../../../public/static/icons/GesKursWildbret.svg');
const GesWildkunde = require('./../../../../public/static/icons/GesWildkunde.svg');
const IconFeatherImage = require('./../../../../public/static/icons/IconFeatherImage.svg');
const IconIonicIosSearch = require('./../../../../public/static/icons/IconIonicIosSearch.svg');
const IconIonicIosSettings = require('./../../../../public/static/icons/IconIonicIosSettings.svg');
const JagdlebenEinzeilig = require('./../../../../public/static/icons/JagdlebenEinzeilig.svg');
const JagdlebenEinzeiligAkademie = require('./../../../../public/static/icons/JagdlebenEinzeiligAkademie.svg');
const JagdlebenEinzeiligMagazin = require('./../../../../public/static/icons/JagdlebenEinzeiligMagazin.svg');
const JagdlebenEinzeiligTeppeSchwenen = require('./../../../../public/static/icons/JagdlebenEinzeiligTeppeSchwenen.svg');
const Lock = require('./../../../../public/static/icons/Lock.svg');
const LockOpen = require('./../../../../public/static/icons/LockOpen.svg');
const LogOut = require('./../../../../public/static/icons/LogOut.svg');
const MagKoesRaeuchern = require('./../../../../public/static/icons/MagKoesRaeuchern.svg');
const MagKoesWildkueche = require('./../../../../public/static/icons/MagKoesWildkueche.svg');
const MagKoesWildverwertung = require('./../../../../public/static/icons/MagKoesWildverwertung.svg');
const MagPrJagdpraxis = require('./../../../../public/static/icons/MagPrJagdpraxis.svg');
const MagPraJagdhund = require('./../../../../public/static/icons/MagPraJagdhund.svg');
const MagPraWildbiologie = require('./../../../../public/static/icons/MagPraWildbiologie.svg');
const MagTechAusruestung = require('./../../../../public/static/icons/MagTechAusruestung.svg');
const MagTechOptik = require('./../../../../public/static/icons/MagTechOptik.svg');
const MagTechWaffen = require('./../../../../public/static/icons/MagTechWaffen.svg');
const Menu = require('./../../../../public/static/icons/Menu.svg');
const NewTab = require('./../../../../public/static/icons/NewTab.svg');
const OnDemandVideo2 = require('./../../../../public/static/icons/OnDemandVideo2.svg');
const Password = require('./../../../../public/static/icons/Password.svg');
const PasswordHide = require('./../../../../public/static/icons/PasswordHide.svg');
const Search = require('./../../../../public/static/icons/Search.svg');
const SlideOpen = require('./../../../../public/static/icons/SlideOpen.svg');
const Clock = require('./../../../../public/static/icons/Clock.svg');
const Bubble = require('./../../../../public/static/icons/Bubble.svg');
const BubbleNotStarted = require('./../../../../public/static/icons/Bubble-NotStarted.svg');
const BubbleInProgress = require('./../../../../public/static/icons/Bubble-InProgress.svg');
const Warenkorb = require('./../../../../public/static/icons/Warenkorb.svg');
const WarenkorbBefuellt = require('./../../../../public/static/icons/WarenkorbBefuellt.svg');
const Icon_done = require('./../../../../public/static/icons/Icon_done.svg');
const Icon_close = require('./../../../../public/static/icons/Icon_close.svg');
const Icon_close_button = require('./../../../../public/static/icons/Icon_close_button.svg');
const Icon_exclamation = require('./../../../../public/static/icons/Icon_exclamation.svg');
const Facebook = require('./../../../../public/static/icons/facebook.svg');
const Youtube = require('./../../../../public/static/icons/youtube.svg');
const Instagram = require('./../../../../public/static/icons/instagram.svg');
const PodcastsApple = require('./../../../../public/static/icons/PodcastsApple.svg');
const PodcastsGoogle= require('./../../../../public/static/icons/PodcastsGoogle.svg');
const PodcastsSpotify = require('./../../../../public/static/icons/PodcastsSpotify.svg');
const Share = require('./../../../../public/static/icons/Share.svg');
const OnDemandVideo = require('./../../../../public/static/icons/OnDemandVideo.svg');
const Checklist = require('./../../../../public/static/icons/Checklist.svg');
const Cube = require('./../../../../public/static/icons/Cube.svg');
const ContentCopy = require('./../../../../public/static/icons/ContentCopy.svg');
const Settings = require('./../../../../public/static/icons/Settings.svg');
const Calendar = require('./../../../../public/static/icons/Calendar.svg');
const play = require('./../../../../public/static/icons/play.svg');
const playWhite = require('./../../../../public/static/icons/play-white.svg');
const playHover = require('./../../../../public/static/icons/play-hover.svg');
const ArrowRotated = require('./../../../../public/static/icons/ArrowRotated.svg');
const CheckCircle = require('./../../../../public/static/icons/Check-Circle.svg');
const Trash = require('./../../../../public/static/icons/Trash.svg');
const Pen = require('./../../../../public/static/icons/Pen.svg');
const Rabbit = require('./../../../../public/static/icons/Rabbit.svg');
const Learn = require('./../../../../public/static/icons/Learn.svg');
const Geld = require('./../../../../public/static/icons/Geld.svg');
const AmazonPay = require('./../../../../public/static/icons/payment/AmazonPay.svg');
const ApplePay = require('./../../../../public/static/icons/payment/ApplePay.svg');
const GiroPay = require('./../../../../public/static/icons/payment/GiroPay.svg');
const GooglePay = require('./../../../../public/static/icons/payment/GooglePay.svg');
const Klarna = require('./../../../../public/static/icons/payment/Klarna.svg');
const PayPal = require('./../../../../public/static/icons/payment/Paypal.svg');
const Visa = require('./../../../../public/static/icons/payment/Visa.svg');
const Mastercard = require('./../../../../public/static/icons/payment/Mastercard.svg');
const Dropdown = require('./../../../../public/static/icons/Dropdown.svg');
const Certificate = require('./../../../../public/static/icons/Certificate.svg');
const Dots = require('./../../../../public/static/icons/Dots.svg');
const Email = require('./../../../../public/static/icons/Email.svg');
const Key = require('./../../../../public/static/icons/Key.svg');
const TikTok = require('./../../../../public/static/icons/TikTok.svg');
const Whatsapp = require('./../../../../public/static/icons/Whatsapp.svg');
const Mail = require('./../../../../public/static/icons/Mail.svg');
const LinkedIn = require('./../../../../public/static/icons/LinkedIn.svg');
const StrikethroughPrice = require('./../../../../public/static/icons/StrikethroughPrice.svg');
const Standort = require('./../../../../public/static/icons/Standort.svg');

//elements for demo
const svgElements = [
    AmazonPay,
    ApplePay,
    GiroPay,
    GooglePay,
    Klarna,
    PayPal,
    Visa,
    Mastercard,
    Facebook,
    Instagram,
    Youtube,
    Arrow,
    ArrowNav,
    Arrow2,
    Avatar,
    Avatar_angemeldet,
    Avatar_stern,
    Wrench,
    CloseError,
    Check,
    Download,
    Feedback,
    GesKurs_Hege,
    GesKursJagdpraxis,
    GesKursNaturschutz,
    GesKursRecht,
    GesKursWaffenMunitionOptik,
    GesKursWildbret,
    GesWildkunde,
    IconFeatherImage,
    IconIonicIosSearch,
    IconIonicIosSettings,
    JagdlebenEinzeilig,
    JagdlebenEinzeiligAkademie,
    JagdlebenEinzeiligMagazin,
    JagdlebenEinzeiligTeppeSchwenen,
    Lock,
    LockOpen,
    LogOut,
    MagKoesRaeuchern,
    MagKoesWildkueche,
    MagKoesWildverwertung,
    MagPrJagdpraxis,
    MagPraJagdhund,
    MagPraWildbiologie,
    MagTechAusruestung,
    MagTechOptik,
    MagTechWaffen,
    Menu,
    NewTab,
    OnDemandVideo2,
    Password,
    PasswordHide,
    Search,
    SlideOpen,
    Clock,
    Bubble,
    Warenkorb,
    WarenkorbBefuellt,
    Icon_done,
    Icon_close,
    Icon_close_button,
    Icon_exclamation,
    PodcastsApple,
    PodcastsGoogle,
    PodcastsSpotify,
    Share,
    OnDemandVideo,
    Checklist,
    Cube,
    ContentCopy,
    Calendar,
    play,
    playWhite,
    playHover,
    Settings,
    ArrowRotated,
    Geld,
    Rabbit,
    Learn,
    CheckCircle,
    Trash,
    Pen,
    Dropdown,
    Certificate,
    Dots,
    Email,
    Key,
    TikTok,
    Whatsapp,
    Mail,
    LinkedIn,
    StrikethroughPrice,
    Standort
];

// load sprite
const xhr = new XMLHttpRequest();
const rootUrl = [window.location.protocol, '//', window.location.host].join('');

xhr.open('GET', rootUrl + '/static/svgs/sprite.svg', true);
xhr.addEventListener('load', () => {
    const div = document.createElement('div');

    div.className = 'as__icon__template';
    div.innerHTML = xhr.responseText;
    document.body.appendChild(div);
});
xhr.send();


// show demo
window.addEventListener('DOMContentLoaded', () => {
    const demoRoot = document.getElementById('iconDemo');

    if(!demoRoot) {
        return
    }

    svgElements.forEach((svgData) => {
        const usage = `<svg role="img" class="as__icon"><use xlink:href="#${svgData.default.id.replace('-usage', '')}"></use></svg>`;


        demoRoot.innerHTML += '<h2>Normal:</h2>';
        demoRoot.innerHTML += usage;
        demoRoot.append(usage);
        demoRoot.innerHTML += '<br/>';

        const usageReverse = `<svg role="img" class="as__icon as__icon--reverse"><use xlink:href="#${svgData.default.id.replace('-usage', '')}"></use></svg>`;

        demoRoot.innerHTML += '<h2>Reverse:</h2>';
        demoRoot.innerHTML += usageReverse;
        demoRoot.append(usageReverse);
        demoRoot.innerHTML += '<br/>';

        const usageFlipVertical = `<svg role="img" class="as__icon as__icon--flip-vertically"><use xlink:href="#${svgData.default.id.replace('-usage', '')}"></use></svg>`;

        demoRoot.innerHTML += '<h2>Flip Vertical:</h2>';
        demoRoot.innerHTML += usageFlipVertical;
        demoRoot.append(usageFlipVertical);
        demoRoot.innerHTML += '<br/>';
    })
});
