interface FeedbackDOM {
    feedbackForm: HTMLElement,
    inputsListen: Array<HTMLElement> | null
}

export default class FeedbackForm {
    public static readonly Selectors = {
        Root: '.js-feedback-form',
        InputsListen: '.js-feedback__input'
    }

    private static readonly Attributes = {
        DataTargets: 'data-targets',
        DataTargetLabel: 'data-target-label'
    }

    private readonly dom: FeedbackDOM;

    public constructor(element: HTMLElement) {
        this.dom = {
            feedbackForm: element,
            inputsListen: Array.prototype.slice.call(element.querySelectorAll(FeedbackForm.Selectors.InputsListen))
        }

        this.addEventListenerInputsListen();
    }

    private addEventListenerInputsListen() {
        if (!(this.dom.inputsListen && this.dom.inputsListen.length)) return;
        this.dom.inputsListen.forEach(elem => {
            if (!(elem instanceof HTMLInputElement)) return;
            const attributeTargetInputs = elem.getAttribute(FeedbackForm.Attributes.DataTargets);
            const attributeTargetLabel = elem.getAttribute(FeedbackForm.Attributes.DataTargetLabel);
            if (attributeTargetInputs === null) return;
            if (attributeTargetLabel === null) return;
            const targetLabel = this.dom.feedbackForm.querySelector(attributeTargetLabel);
            if (!(targetLabel instanceof HTMLLabelElement)) return;
            const targetInputs = JSON.parse(attributeTargetInputs);
            if (!(targetInputs instanceof Array)) return;
            elem.addEventListener('change', (e) => {
                targetLabel.classList.toggle('form-label--disabled');
                const checked = !elem.checked;
                targetInputs.forEach(targetInput => {
                    targetInput = this.dom.feedbackForm.querySelector(targetInput);
                    if (!(targetInput instanceof HTMLSelectElement)) return;
                    if (checked) {
                        targetInput.setAttribute('disabled', 'true');
                    } else {
                        targetInput.removeAttribute('disabled');
                    }
                });
            });
        });
    }
}

document.addEventListener('DOMContentLoaded', (e) => {
    const feedback = document.querySelector(FeedbackForm.Selectors.Root);
    if (!(feedback instanceof HTMLElement)) return;
    new FeedbackForm(feedback);
});