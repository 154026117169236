import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import Shariff = require("shariff");

onDOMContentLoaded(() => {
    document.querySelectorAll('.gu__shariff').forEach((shariffElement) => {
        new Shariff(shariffElement, {
            services: 'mail, whatsapp,facebook,linkedin',
            orientation: 'horizontal',
            buttonStyle: 'icon'
        });
    });
});
