window.addEventListener('DOMContentLoaded', () => {
    const labels = document.querySelectorAll('.form-label');

    labels.forEach((label) => {
        const formElement = label.nextElementSibling;

        if(formElement && formElement.getAttribute('disabled')) {
            label.classList.add('form-label--disabled');
        }
    });
});