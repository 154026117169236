interface ListDOM {
    root: HTMLElement,
    licensesCountText: HTMLElement | null,
    submitBtn: HTMLAnchorElement | null,
    input: Array<HTMLElement> | null,
    licensesAssign: number,
    licensesCount: number
}

export default class LicensesAssign {
    public static readonly Selectors = {
        Root: '.gu__licenses-assign',
        LicensesCountText: '.gu__licenses-assign__licenses-count',
        Input: '.gu__licenses-assign__input',
        SubmitBtn: '.gu__licenses-assign__submit-btn'
    }

    public static readonly Attributes = {
        NumberLicenses: 'data-number-licenses',
        TargetStudents: 'data-target-students',
        StudentCount: 'data-student-count',
        InputType: 'data-input-type'
    }

    private readonly dom: ListDOM;

    public constructor(element: HTMLElement) {
        this.dom = {
            root: element,
            licensesCountText: element.querySelector(LicensesAssign.Selectors.LicensesCountText),
            input: Array.prototype.slice.call(element.querySelectorAll(LicensesAssign.Selectors.Input)),
            submitBtn: element.querySelector(LicensesAssign.Selectors.SubmitBtn),
            licensesAssign: 0,
            licensesCount: 0
        }
        this.getLicensesCount();
        this.addEventListenerToInput();
        this.changeLicenseCountSubmitBtn();
    }

    private getLicensesCount() {
        if (!this.dom.licensesCountText) return;
        const numberLicenses = this.dom.licensesCountText.getAttribute(LicensesAssign.Attributes.NumberLicenses);
        if (numberLicenses === null) return;
        this.dom.licensesCount = parseInt(numberLicenses);
    }

    private addEventListenerToInput() {
        if (!this.dom.input || !this.dom.input.length) return;
        this.dom.input.forEach((elem) => {
            if (!(elem instanceof HTMLInputElement)) return;
            elem.addEventListener('change', this.onInputChange.bind(this));
        });
    }

    private onInputChange(event: Event) {
        const currentInput = event.target as HTMLInputElement | null;
        if (!(currentInput instanceof HTMLInputElement)) return;
        const inputType = currentInput.getAttribute(LicensesAssign.Attributes.InputType);
        if (inputType === "monthyear") {
            const assignedLicensesBeforeAdding = this.dom.licensesAssign;
            const studentCountDate = currentInput.getAttribute(LicensesAssign.Attributes.StudentCount);
            if (studentCountDate === null) return;
            const target = event.target;
            if (!(target instanceof HTMLInputElement)) return;
            const targetInputs = target.getAttribute(LicensesAssign.Attributes.TargetStudents);
            if (targetInputs === null) return;
            const checkedInputsDate = this.checkCheckedInputs(targetInputs);
            if (target.checked) {
                if (this.dom.licensesAssign + parseInt(studentCountDate) - checkedInputsDate > this.dom.licensesCount) {
                    this.dom.licensesAssign = this.dom.licensesCount;
                } else {
                    this.dom.licensesAssign += parseInt(studentCountDate) - checkedInputsDate;
                }
                this.changeStudentInputs(targetInputs, target.checked, this.dom.licensesAssign - assignedLicensesBeforeAdding);
            } else {
                this.dom.licensesAssign -= checkedInputsDate;
                this.uncheckAllInputs(targetInputs);
            }

            if (this.dom.licensesAssign <= 0) this.uncheckAllInputs('');
        } else if (inputType === "student") {
            const target = event.target;
            if (!(target instanceof HTMLInputElement)) return;
            if (target.checked) {
                this.dom.licensesAssign++;
            } else {
                this.dom.licensesAssign--;
            }
        }
        if (this.dom.licensesAssign <= 0) {
            this.dom.licensesAssign = 0
            if (!(this.dom.submitBtn instanceof HTMLAnchorElement)) return;
            this.dom.submitBtn.classList.add('disabled');
        } else {
            if (!(this.dom.submitBtn instanceof HTMLAnchorElement)) return;
            this.dom.submitBtn.classList.remove('disabled');
        }
        this.checkMaxLicensesReached();
        this.changeBgTextLicensesCount();
        this.changeLicenseCountSubmitBtn();
    }

    private uncheckAllInputs(targets: string) {
        if (targets == '') {
            if (!this.dom.input || !this.dom.input.length) return;
            this.dom.input.forEach(elem => {
                if (!(elem instanceof HTMLInputElement)) return;
                elem.checked = false;
            })
        } else {
            const targetInputs = this.dom.root.querySelectorAll(targets);
            targetInputs.forEach((elem, index) => {
                if (!(elem instanceof HTMLInputElement)) return;
                elem.checked = false;
            })
        }
    }

    private checkCheckedInputs(targets: string) {
        let checkedInputs: number = 0;
        const targetInputs = this.dom.root.querySelectorAll(targets);
        targetInputs.forEach((elem, index) => {
            if (!(elem instanceof HTMLInputElement)) return;
            if (elem.checked) checkedInputs++;
        })
        return checkedInputs;
    }

    private changeStudentInputs(targets: string, checked: boolean, numIterations: number) {
        const targetInputs = this.dom.root.querySelectorAll(targets);
        targetInputs.forEach((elem, index) => {
            if (!(elem instanceof HTMLInputElement)) return;
            if (elem.checked) numIterations++;
            if (index >= numIterations) return;
            elem.checked = checked;
        })
    }

    private checkMaxLicensesReached() {
        if (!this.dom.input || !this.dom.input.length) return;
        const maxReached = this.dom.licensesAssign >= this.dom.licensesCount;
        this.dom.input.forEach((elem) => {
            if (!(elem instanceof HTMLInputElement)) return;
            if (!elem.checked && maxReached) {
                elem.setAttribute('disabled', '');
            }
            if (!elem.checked && !maxReached) {
                elem.removeAttribute('disabled');
            }
        });
    }

    private changeLicenseCountSubmitBtn() {
        if (!(this.dom.submitBtn instanceof HTMLAnchorElement)) return;
        if (this.dom.licensesAssign == 0) {
            this.dom.submitBtn.textContent = "Lizenzen zuweisen";
        } else {
            if (this.dom.licensesAssign == 1) {
                this.dom.submitBtn.textContent = this.dom.licensesAssign + " Lizenz zuweisen";
            } else {
                this.dom.submitBtn.textContent = this.dom.licensesAssign + " Lizenzen zuweisen";
            }
        }
    }

    private changeBgTextLicensesCount() {
        if (!(this.dom.licensesCountText instanceof HTMLDivElement)) return;
        this.dom.licensesCountText.classList.remove("bg-warning", "bg-primary", "bg-danger");
        if (this.dom.licensesAssign === 0) {
            this.dom.licensesCountText.classList.toggle("bg-warning");
        } else if (this.dom.licensesAssign === this.dom.licensesCount) {
            this.dom.licensesCountText.classList.toggle("bg-danger");
        } else {
            this.dom.licensesCountText.classList.toggle("bg-primary");
        }

        this.dom.licensesCountText.textContent = this.dom.licensesAssign + ' von ' + this.dom.licensesCount + ' Lizenzen zugeordnet';
    }
}

document.addEventListener('DOMContentLoaded', (e) => {
    const licensesAssign = document.querySelector(LicensesAssign.Selectors.Root);
    if (!(licensesAssign instanceof HTMLElement)) return;
    new LicensesAssign(licensesAssign);

});