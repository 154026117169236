import {Offcanvas} from "bootstrap";

const bootstrap = require('bootstrap');

interface ListDOM {
    root: HTMLElement,
    offCanvas: HTMLElement | null
}

const MATCH_MEDIA = window.matchMedia('(min-width: 576px)');

export default class TileList {
    public static readonly Selectors = {
        Root: '.js-tile-list',
        OffCanvas: '.js-tile-list-offcanvas-container'
    }

    private readonly dom: ListDOM;
    private offCanvasInstance: Offcanvas | null = null;
    public constructor(element: HTMLElement) {
        this.dom = {
            root: element,
            offCanvas: element.querySelector(TileList.Selectors.OffCanvas)
        }

        if(!(this.dom.offCanvas instanceof HTMLElement)) return;

        this.offCanvasInstance = bootstrap.Offcanvas.getInstance(this.dom.offCanvas);

        MATCH_MEDIA.addEventListener('change', this.onMatchMediaChange.bind(this));

    }

    private onMatchMediaChange() {
        if(!(this.offCanvasInstance instanceof Offcanvas)) return;
        if (MATCH_MEDIA.matches) {
            this.offCanvasInstance.hide();
        }
    }
}

document.addEventListener('DOMContentLoaded', (e) => {
    const tileList = document.querySelector(TileList.Selectors.Root);
    if (!(tileList instanceof HTMLElement)) return;
    new TileList(tileList);
});