interface CustomSelectDOM {
    customSelectContainer: HTMLElement|null;
    customSelect: HTMLElement|null;
    customSelectInputs: Array<HTMLInputElement>;
}

export default class CustomSelect  {
    public static readonly Selectors = {
        Container: '.js-custom-select__container',
        CustomSelect: '.js-custom-select',
        CustomSelectInputs: '.js-custom-select__input'
    }

    private static readonly States = {
        HasValue: 'has-value'
    };

    private readonly dom: CustomSelectDOM;
    public constructor(element: HTMLElement) {
        this.dom = {
            customSelectContainer: element,
            customSelect: element.querySelector(CustomSelect.Selectors.CustomSelect),
            customSelectInputs: Array.prototype.slice.call(element.querySelectorAll(CustomSelect.Selectors.CustomSelectInputs)),
        };

        this.dom.customSelectInputs.forEach(customSelectInputElement => {
            customSelectInputElement.addEventListener('change', this.onCustomSelectInputChange.bind(this))
        });
    }

    // Toggle custom select container element "has value" state based on all inputs value
    private onCustomSelectInputChange() {
        if (!this.dom.customSelect) return;

        const hasAnyCustomSelectInputValue = this.dom.customSelectInputs.filter(customSelectInputElement => !!customSelectInputElement.checked).length

        if (hasAnyCustomSelectInputValue) {
            this.dom.customSelect.classList.add(CustomSelect.States.HasValue)
        } else {
            this.dom.customSelect.classList.remove(CustomSelect.States.HasValue)
        }
    }
}

document.addEventListener('DOMContentLoaded', (evt) => {
    const customSelectContainerElems = Array.prototype.slice.call(document.querySelectorAll(CustomSelect.Selectors.Container))

    customSelectContainerElems.forEach(customSelectContainerElem => {
        if (!(customSelectContainerElem instanceof HTMLElement)) return
        new CustomSelect(customSelectContainerElem)
    });
});
