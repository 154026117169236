const bootstrap = require('bootstrap');

// import main.less to compile less
import './main.less';

// base
import './base/base';
import './base/icon/icon';
import './base/form/password';
import './base/form/label';
import './base/form/custom-select';

// components
import './components/top-button/top-button';
import './components/video/video';
import './components/tile-list/tile-list';
import './components/shariff/shariff';

// modules
import './modules/navigation/navigation/navigation';
import './modules/m7-carousel/m7-carousel';
import './modules/breadcrumb/breadcrumb';
import './modules/header/header';
import './modules/cookie-banner/cookie-banner';

// sites
import './sites/account/events';
import './sites/dashboard/dashboard';
import './sites/product/list';
import './sites/feedback/feedback';
import './sites/trainer/licenses-assign/licenses-assign';
