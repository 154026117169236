window.addEventListener('DOMContentLoaded', () => {
    const passwords = document.querySelectorAll('.form-control[type="password"]');
    const passwordSvg = require('./../../../../public/static/icons/Password.svg');
    const passwordHideSvg = require('./../../../../public/static/icons/PasswordHide.svg');
    const visibleClass = ".gu__password-icon--visible-eye";
    const hiddenClass = ".gu__password-icon--hidden-eye";

    function passwordToggleType(element: HTMLInputElement) {
        const visibleEye = element.parentElement?.querySelector(visibleClass);
        const hiddenEye = element.parentElement?.querySelector(hiddenClass);

        if (element.type === "password") {
            element.setAttribute("type", "text")
            element.classList.add('gu__password');
            visibleEye?.classList.add('invisible');
            hiddenEye?.classList.remove('invisible');
        } else {
            element.setAttribute("type", "password")
            element.classList.remove('gu__password');
            visibleEye?.classList.remove('invisible');
            hiddenEye?.classList.add('invisible');
        }
    }

    passwords.forEach((element: Element) => {
        const wrapperElement = document.createElement('div');
        const parentElement = element.parentElement;

        if (!(parentElement instanceof HTMLElement)) {
            return
        }

        wrapperElement.classList.add('gu__password-icon__wrapper');
        parentElement.insertBefore(wrapperElement, element);
        wrapperElement.before(element);
        wrapperElement.innerHTML += `<span class="gu__password-icon gu__password-icon--visible-eye"><svg role="img" class="as__icon"><use xlink:href="#${passwordSvg.default.id.replace('-usage', '')}"></use></svg></span>`;
        wrapperElement.innerHTML += `<span class="gu__password-icon gu__password-icon--hidden-eye invisible"><svg role="img" class="as__icon"><use xlink:href="#${passwordHideSvg.default.id.replace('-usage', '')}"></use></svg></span>`;

        parentElement.querySelectorAll('.as__icon').forEach((icon) => {
            icon.addEventListener('click', () => {
                passwordToggleType(element as HTMLInputElement);
            })
        })
    })
});
