import onDOMContentLoaded from '../../base/util/dom/onDOMContentLoaded';
import CookieBanner, {
    COOKIE_BANNER_EVENT_LOADED_COOKIE_BANNER,
    COOKIE_BANNER_EVENT_SET_COOKIE_SETTINGS
} from '../../modules/cookie-banner/cookie-banner';

interface VideoCookieOverlayDOM {
    overlay: HTMLElement;
}

export default class VideoCookieOverlay {
    public static readonly Selectors = {
        Overlay: '.js-video-cookie-overlay',
    };

    private static readonly Attributes = {
        RequiredCookies: 'data-required-cookies',
    };

    private static readonly States = {
        AllRequiredCookiesSet: 'js-video-cookie-overlay--all-required-cookies-set'
    }

    private readonly dom: VideoCookieOverlayDOM;
    private readonly requiredCookies: Array<string>;
    public allRequiredCookiesSet: boolean = false;
    public constructor(element: HTMLElement) {
        this.dom = {
            overlay: element
        }

        this.requiredCookies = JSON.parse(this.dom.overlay.getAttribute(VideoCookieOverlay.Attributes.RequiredCookies) ?? '[]')

        if (!this.requiredCookies.length) {
            this.allRequiredCookiesSet = true
            this.toggleOverlayState(this.allRequiredCookiesSet)
            return
        }

        document.addEventListener(COOKIE_BANNER_EVENT_LOADED_COOKIE_BANNER, this.onCookieBannerLoaded.bind(this))
    }

    private onCookieBannerLoaded() {
        window.AS = window.AS || {}
        if (!(window.AS.cookieBanner instanceof CookieBanner)) return

        this.checkIfAllRequiredCookiesSet();
        window.AS.cookieBanner.addEventListener(COOKIE_BANNER_EVENT_SET_COOKIE_SETTINGS, this.checkIfAllRequiredCookiesSet.bind(this));
    }

    private checkIfAllRequiredCookiesSet() {
        window.AS = window.AS || {}
        if (!(window.AS.cookieBanner instanceof CookieBanner)) return

        const setRequiredCookies = [] as Array<string>;

        this.requiredCookies.forEach(requiredCookie => window.AS.cookieBanner.getCookie(requiredCookie) && setRequiredCookies.push(window.AS.cookieBanner.getCookie(requiredCookie)))

        if (!setRequiredCookies.length) {
            this.allRequiredCookiesSet = false
            this.toggleOverlayState(this.allRequiredCookiesSet)
            return
        }

        const acceptedRequiredCookies = setRequiredCookies.filter(setRequiredCookie => setRequiredCookie.split('=').length == 2 && setRequiredCookie.split('=')[1] === 'true')

        this.allRequiredCookiesSet = setRequiredCookies.length === acceptedRequiredCookies.length
        this.toggleOverlayState(this.allRequiredCookiesSet)
    }

    public toggleOverlayState(showHide: boolean) {
        this.dom.overlay.classList.toggle(VideoCookieOverlay.States.AllRequiredCookiesSet, showHide)
    }
}
