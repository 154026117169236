import BodyNoScroll from '../../base/util/dom/BodyNoScroll';
import {AxiosResponse} from "axios";
const axios = require('axios');

interface ListDOM {
    filterContainer: HTMLElement | null;
    loadMoreButtonContainer: Array<HTMLElement>;
    loadMoreButtons: Array<HTMLButtonElement>;
    productList: HTMLElement | null;
    filterMobileToggle: HTMLInputElement | null;
    filterForm: HTMLFormElement | null;
    filterFormFilters: Array<HTMLInputElement>;
    activeFilterFormFilters: Array<HTMLElement>;
}

const FILTER_MOBILE_IDENTIFIER : string = 'mobileProductlistFilter';
const MATCH_MEDIA = window.matchMedia('(min-width: 768px)');

export default class ProductListing {
    public static readonly Selectors = {
        Root: ".gu__product-list",
        FilterContainer: ".gu__product-filters__container",
        LoadMoreButtonContainer: '.gu__product-list__load-more-container',
        LoadMoreButtons: '.gu__product-list__load-more',
        ProductList: '#gu__product-list__list',
        FilterMobileToggle: '#gu__product-list__mobile-filter-toggle',
        FilterForm: '#gu__product-filters__form',
        FilterFormFilters: '.gu__product-filters__form-filter',
        ActiveFilterFormFilters: '.gu__product-filters__active-filter'
    };

    private static readonly Attributes = {
        EndPage: "data-end-page",
        LoadMoreUri: "data-load-more-uri",
    };

    private static readonly States = {
        LoadMoreButtonContainerHidden: 'gu__product-list__load-more-container--hidden'
    };

    private readonly dom: ListDOM;
    private loadMoreLastPage: number = 2;
    private loadMoreNextPage: number = 2;
    private loadMoreUri: string|null = null;
    public constructor(element: HTMLElement) {
        this.dom = {
            filterContainer: element.querySelector(ProductListing.Selectors.FilterContainer),
            loadMoreButtonContainer: Array.prototype.slice.call(element.querySelectorAll(ProductListing.Selectors.LoadMoreButtonContainer)),
            loadMoreButtons: Array.prototype.slice.call(element.querySelectorAll(ProductListing.Selectors.LoadMoreButtons)),
            productList: element.querySelector(ProductListing.Selectors.ProductList),
            filterMobileToggle: document.querySelector(ProductListing.Selectors.FilterMobileToggle),
            filterForm: element.querySelector(ProductListing.Selectors.FilterForm),
            filterFormFilters: Array.prototype.slice.call(element.querySelectorAll(ProductListing.Selectors.FilterFormFilters)),
            activeFilterFormFilters: Array.prototype.slice.call(element.querySelectorAll(ProductListing.Selectors.ActiveFilterFormFilters))
        };

        this.loadMoreUri = element.getAttribute(ProductListing.Attributes.LoadMoreUri) ?? null;

        if (this.dom.loadMoreButtons.length && this.loadMoreUri) {
            this.loadMoreLastPage = Number.parseInt(element.getAttribute(ProductListing.Attributes.EndPage) ?? '');
            this.dom.loadMoreButtons.forEach(loadMoreButtonElem => {
                loadMoreButtonElem.addEventListener('click', this.onLoadMoreClick.bind(this));
            });
        }

        if (!(this.dom.filterForm instanceof HTMLFormElement)) return;
        this.dom.filterFormFilters.forEach(filterFormFilterElem => {
            filterFormFilterElem.addEventListener('change', this.onFilterFormFilterChange.bind(this));
        });
        this.dom.activeFilterFormFilters.forEach(activeFilterFormFilterElem => {
            activeFilterFormFilterElem.addEventListener('click', this.onActiveFilterFormFilterClick.bind(this));
        });

        if (!(this.dom.filterMobileToggle instanceof HTMLInputElement)) return;
        this.dom.filterMobileToggle.addEventListener('change', this.onFilterMobileToggleChange.bind(this));
        MATCH_MEDIA.addEventListener('change', this.onMatchMediaChange.bind(this));
    }

    // Reset related filter element if related filter element is [type=radio] input
    private onActiveFilterFormFilterClick(event: Event) {
        if (!event || !event.target || !this.dom.filterContainer) return;

        let activeFilterFormFilter = event.target as HTMLElement|null;

        if (!activeFilterFormFilter) return;

        if (!this.dom.activeFilterFormFilters.includes(activeFilterFormFilter)) {
            activeFilterFormFilter = activeFilterFormFilter.closest(ProductListing.Selectors.ActiveFilterFormFilters);
        }

        if (!activeFilterFormFilter) return;

        const relatedFilterFormFilterID = activeFilterFormFilter.getAttribute('for');

        if (!relatedFilterFormFilterID) return;

        const relatedFilterFormFilter = this.dom.filterContainer.querySelector('#' + relatedFilterFormFilterID);

        if (!(relatedFilterFormFilter instanceof HTMLInputElement)) return;
        if (relatedFilterFormFilter.type !== 'radio') return;

        event.preventDefault();
        relatedFilterFormFilter.checked = false;
        this.onFilterFormFilterChange();
    }

    private onFilterFormFilterChange() {
        if (!(this.dom.filterForm instanceof HTMLFormElement)) return;
        if (MATCH_MEDIA.matches) this.dom.filterForm.submit();
    }

    // Remove BodyNoScroll if view is bigger than 768px
    // If view changes from desktop to mobile breakpoint close mobile filter
    private onMatchMediaChange() {
        if (MATCH_MEDIA.matches) {
            BodyNoScroll.toggleNoScroll(FILTER_MOBILE_IDENTIFIER, false);
        } else {
            if (!(this.dom.filterMobileToggle instanceof HTMLInputElement)) return;
            this.dom.filterMobileToggle.checked = false;
        }
    }

    private onFilterMobileToggleChange() {
        if (!(this.dom.filterMobileToggle instanceof HTMLInputElement)) return;
        BodyNoScroll.toggleNoScroll(FILTER_MOBILE_IDENTIFIER, this.dom.filterMobileToggle.checked);
    }

    private onLoadMoreClick(event: Event) {
        if(!this.loadMoreUri || !this.dom.loadMoreButtons || !this.dom.productList) return;
        if (!(event.target instanceof HTMLButtonElement) || event.target.getAttribute('disabled')) return;
        this.dom.loadMoreButtons.forEach(loadMoreButtonElem => loadMoreButtonElem.setAttribute('disabled', 'disabled'));

        axios.get(this.loadMoreUri, {
            params: {
                page: this.loadMoreNextPage
            }
        }).then((res: AxiosResponse) => {
            if (!this.dom.productList) return;
            this.dom.productList.innerHTML += res.data;
            this.loadMoreNextPage++;
            this.dom.loadMoreButtons.forEach(loadMoreButtonElem => this.loadMoreNextPage <= this.loadMoreLastPage && loadMoreButtonElem.removeAttribute('disabled'));
            this.dom.loadMoreButtonContainer.forEach(loadMoreButtonContainerElem => this.loadMoreNextPage > this.loadMoreLastPage && loadMoreButtonContainerElem.classList.add(ProductListing.States.LoadMoreButtonContainerHidden));
        })
        .catch((error : AxiosResponse)=> {
            console.log(error);
        });
    }
}

document.addEventListener('DOMContentLoaded', (evt) => {
    const listElem = document.querySelector(ProductListing.Selectors.Root);
    if (!(listElem instanceof HTMLElement)) return;
    new ProductListing(listElem);
});
