import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    const breadcrumb = document.querySelector('.gu__breadcrumb');
    if (!breadcrumb || !(breadcrumb instanceof HTMLElement))
        return;

    const mediaQuery = window.matchMedia('(max-width: 575px)')
    const activeBreadcrumbItems = breadcrumb.querySelectorAll('.gu__breadcrumb-item--active');
    const handleMobileChange = (mediaQuery:MediaQueryList) => {
        if (mediaQuery.matches && !!activeBreadcrumbItems.length) {
            activeBreadcrumbItems[activeBreadcrumbItems.length - 1].classList.add('gu__breadcrumb-item--show-mobile')
        }
    }

    mediaQuery.addEventListener('change', () =>{
        handleMobileChange(mediaQuery);
    })
    handleMobileChange(mediaQuery);
})
