import {AxiosResponse} from "axios";
const axios = require('axios');
import EventTarget, {createCustomEvent} from '../../base/util/dom/EventTarget';

interface CookieBannerDOM {
    banner: HTMLElement;
    checkboxElems: Array<HTMLInputElement>;
    checkboxesCategoryWrapperElems: Array<HTMLElement>;
    btnDeclineAllElems: Array<HTMLButtonElement>;
    btnSubmitAllElems: Array<HTMLButtonElement>;
    btnSubmitElems: Array<HTMLButtonElement>;
    cookieItemElems: Array<HTMLElement>;
    cookieCategoryElems: Array<HTMLElement>;
    requiredCookie: HTMLInputElement | null;
    externalCookieBannerToggles: Array<HTMLElement>
}

interface CookieBannerSettings {
    cookieDurationInDays: number;
    gtmEventName: string;
    pathToJSONFile?: string;
    categories: Array<string>;
    content?: CookieBannerContent
}

interface PartialCookieBannerSettings {
    cookieDurationInDays?: number;
    gtmEventName?: string;
    pathToJSONFile?: string;
    categories?: Array<string>;
    content?: CookieBannerContent
}

interface CookieBannerContent {
    labels: CookieBannerCookie,
    cookies?: CookieBannerCookies
}

interface CookieBannerCookies {
    required: Array<CookieBannerCookie>;
    preference?:  Array<CookieBannerCookie>;
    statistic?:  Array<CookieBannerCookie>;
    marketing?:  Array<CookieBannerCookie>;
}

interface CookieBannerCookie {
    cookieName: string;
    name: string;
    provider: string;
    purpose: string;
    expiry: string;
    type: string;
    privacy?: string;
}

export const COOKIE_BANNER_EVENT_LOADED_COOKIE_BANNER = 'cookiebanner:cookieBannerLoaded'
export const COOKIE_BANNER_EVENT_SET_COOKIE_SETTINGS = 'cookiebanner:setCookieSettings'

export default class CookieBanner extends EventTarget {
    public static readonly Selectors = {
        Banner: '.as__cookie-banner',
        Checkboxes: '.as__checkbox__control',
        CheckboxesCategoryWrapper: '#as__cookie-banner__form__list__item--',
        BtnDeclineAll: '.as__cookie-banner__decline-all',
        BtnSubmitAll: '.as__cookie-banner__submit-all',
        BtnSubmit: '.as__cookie-banner__submit',
        BtnToggle: '.as__cookie-banner__toggle',
        CookieItems: '.as__cookie-banner__details__cookies',
        CookieCategory: '.as__cookie-banner__details__item--',
        RequiredCookie: '#as__checkbox__control--required',
        ExternalCookieBannerToggles: '.js-cookie-banner-toggle, a[href="#cookie-banner-open"]',
    };

    private static readonly States = {
        BannerActive: 'as__cookie-banner--active',
        EditMode: 'as__cookie-banner--editmode',
    };

    private static readonly Attributes = {
        NavIterator: 'data-iterator',
        ToggleClass: 'data-toggle-class',
        ToggleTarget: 'data-toggle-target',
    };

    public defaultSettings: CookieBannerSettings = {
        cookieDurationInDays: 30,
        gtmEventName: 'cookieSettingsDone',
        pathToJSONFile: '/static/cookie.json',
        categories: ['required', 'preference', 'statistic', 'marketing'],
        content: {
            labels: {
                cookieName: "Cookie Name",
                name: 'Name',
                provider: "Anbieter",
                purpose: "Zweck",
                expiry: "Ablauf",
                type: "Typ",
                privacy: "Datenschutzerklärung"
            }
        }
    };
    private readonly dom: CookieBannerDOM;
    private readonly cookiePath: string|null = null;
    private readonly settings: CookieBannerSettings;
    private readonly cookieRequest: any;
    private readonly editmode: boolean = false;
    public constructor(element: HTMLElement, settings?: PartialCookieBannerSettings) {
        super();

        this.settings = Object.assign({}, this.defaultSettings, settings || {});

        this.dom = {
            banner: element,
            checkboxElems: Array.prototype.slice.call(element.querySelectorAll(CookieBanner.Selectors.Checkboxes)),
            checkboxesCategoryWrapperElems: Array.prototype.slice.call(element.querySelectorAll(`[id^=${CookieBanner.Selectors.CheckboxesCategoryWrapper.replace('#', '')}]`)),
            btnDeclineAllElems: Array.prototype.slice.call(element.querySelectorAll(CookieBanner.Selectors.BtnDeclineAll)),
            btnSubmitAllElems: Array.prototype.slice.call(element.querySelectorAll(CookieBanner.Selectors.BtnSubmitAll)),
            btnSubmitElems: Array.prototype.slice.call(element.querySelectorAll(CookieBanner.Selectors.BtnSubmit)),
            cookieItemElems: Array.prototype.slice.call(element.querySelectorAll(CookieBanner.Selectors.CookieItems)),
            cookieCategoryElems: Array.prototype.slice.call(element.querySelectorAll(`[class*=${CookieBanner.Selectors.CookieCategory.replace('.', '')}]`)),
            requiredCookie: element.querySelector(CookieBanner.Selectors.RequiredCookie),
            externalCookieBannerToggles: Array.prototype.slice.call(document.querySelectorAll(CookieBanner.Selectors.ExternalCookieBannerToggles)),
        };

        this.editmode = this.dom.banner.classList.contains(CookieBanner.States.EditMode);

        if (this.settings.pathToJSONFile && !this.settings.content) {
            this.cookieRequest = axios.get(this.settings.pathToJSONFile, {})
            .then((res: AxiosResponse) => {
                if (!res || res.status !== 200 || !res.data) throw new Error('1685699252 – no valid cookie banner content response');

                this.settings.content = res.data;
                this.onCookieLoad()
            })
            .catch((error: AxiosResponse|Error)=> {
                this.onCookieError(error)
            });
        }

        if (!this.settings.content) return
        this.onCookieLoad();
    }

    private onCookieLoad() {
        if (!this.dom.requiredCookie) return

        this.renderCookieItems()

        this.dom.btnSubmitElems.forEach(buttonSubmit => {
            buttonSubmit.addEventListener('click', this.onButtonSubmitClick.bind(this));
        });

        this.dom.btnSubmitAllElems.forEach(buttonSubmitAll => {
            buttonSubmitAll.addEventListener('click', this.onButtonSubmitAllClick.bind(this));
        });

        this.dom.btnDeclineAllElems.forEach(buttonToggle => {
            buttonToggle.addEventListener('click', this.onButtonDeclineAllClick.bind(this));
        });

        this.dom.externalCookieBannerToggles.forEach(externalCookieBannerToggle => {
            externalCookieBannerToggle.addEventListener('click', this.onExternalCookieBannerToggleClick.bind(this));
        });

        this.updateCheckboxCheckedStateByRequiredCookiesValues();

        // If requiredCookie "cookieSettingsDown" is already set, don't show cookie banner
        if (this.getCookie(this.dom.requiredCookie.value)) return

        this.toggleCookieBanner(true)
    }

    private getCookie(cookie: string) : string|null {
        if (!document.cookie) return null;

        // @ts-ignore
        const filteredCookieArr = document.cookie.split(';').filter(cookieItem => String(cookieItem).trim().indexOf(cookie) == 0);

        return filteredCookieArr.length ? filteredCookieArr[0] : null
    }

    private renderCookieItems() {
        if (this.hasCookieItems()) {
            this.settings.categories.forEach(category => {
                this.renderCookieItemsbyCategory(category);
            });
        } else {
            this.onCookieError(new Error('1685708046 – Loaded JSON file contains no cookies'));
        }
    }

    private hasCookieItems() {
        return this.settings.content && this.settings.content.cookies && typeof this.settings.content.cookies === 'object' && Object.keys(this.settings.content.cookies).length > 0;
    }

    private renderCookieItemsbyCategory(category: string) {
        let cookiesHTML = '';
        const cookieItemElement = this.getCookieItemElementByCategory(category);

        if (!(cookieItemElement instanceof HTMLElement) ||
            !this.settings.content ||
            !this.settings.content.cookies ||
            // @ts-ignore
            !this.settings.content.cookies[category] || !this.settings.content.cookies[category].length
        ) {

            !this.editmode && this.removeCookieCategory(category);
            return
        }

        // @ts-ignore
        this.settings.content.cookies[category].forEach(cookie => {
            cookiesHTML += this.generateCookieItemHTML(cookie);
        });

        if (cookiesHTML == '') return
        cookieItemElement.innerHTML = cookiesHTML;
    }

    private getCookieCategoryElementsByCategory(category: string) : Array<HTMLElement> | null {
        const filteredCookieCategoryArr = this.dom.cookieCategoryElems.filter(cookieCategoryElem => Array.prototype.slice.call(cookieCategoryElem.classList).join(',').indexOf(category) > -1)
        return filteredCookieCategoryArr.length ? filteredCookieCategoryArr : null
    }

    private getCookieItemElementByCategory(category: string) : HTMLElement | null {
        const filteredCookieItemArr = this.dom.cookieItemElems.filter(cookieItemElem => cookieItemElem.id && cookieItemElem.id.indexOf(category) > -1)
        return filteredCookieItemArr.length ? filteredCookieItemArr[0] : null
    }

    private removeCookieCategory(category: string) {
        const cookieCategoryElems = this.getCookieCategoryElementsByCategory(category),
              checkboxesCategoryWrapperElement = this.getCheckboxesCategoryWrapperElementByCategory(category);

        if (!cookieCategoryElems || !cookieCategoryElems.length) {
            console && console.warn && console.warn(`1685709971 – No cookie category element for category "${category}" found. Element won't be removed.`);
        } else {
            cookieCategoryElems.forEach(cookieCategoryElem => {
                cookieCategoryElem.parentNode && cookieCategoryElem.parentNode.removeChild(cookieCategoryElem);
            });
        }

        if (!checkboxesCategoryWrapperElement) {
            console && console.warn && console.warn(`1685709971 – No cookie checkbox wrapper element for category "${category}" found. Element won't be removed.`);
        } else {
            checkboxesCategoryWrapperElement.parentNode && checkboxesCategoryWrapperElement.parentNode.removeChild(checkboxesCategoryWrapperElement);
        }
    }

    private getCheckboxesCategoryWrapperElementByCategory(category: string) : HTMLElement | null {
        const filteredCheckboxesCategoryWrapperElemsArr = this.dom.checkboxesCategoryWrapperElems.filter(checkboxesCategoryWrapperElement => checkboxesCategoryWrapperElement.id && checkboxesCategoryWrapperElement.id.indexOf(category) > -1)
        return filteredCheckboxesCategoryWrapperElemsArr.length ? filteredCheckboxesCategoryWrapperElemsArr[0] : null
    }

    private generateCookieItemHTML(cookie: CookieBannerCookie) : string {
        let cookieHTML = '';

        cookieHTML = '<div class="as__cookie-banner__table">';

        for (const cookiePropKey in cookie) {
            // @ts-ignore
            if (cookie.hasOwnProperty(cookiePropKey)) {
                // @ts-ignore
                cookieHTML += `<div class="as__cookie-banner__table__head">${this.settings.content.labels[cookiePropKey]}</div>`;
                // @ts-ignore
                cookieHTML += `<div class="as__cookie-banner__table__content${cookiePropKey == 'name' ? ' as__cookie-banner__details__cookies__name' : ''}">${cookie[cookiePropKey]}</div>`;
            }
        }

        cookieHTML += '</div>';

        return cookieHTML;
    }

    private onButtonSubmitClick() {
        if (this.editmode) return;

        this.setCookieSettings();
        this.emitGTMEvent();
        this.toggleCookieBanner(false);
    }

    private setCookieSettings(acceptAllCookies : boolean = false, declineAllCookies : boolean = false) {
        this.dom.checkboxElems.forEach(checkboxElem => {
            if (!(checkboxElem instanceof HTMLInputElement)) return

            if (checkboxElem === this.dom.requiredCookie) {
                this.setCookieConsentCookie(checkboxElem.value, true);
                return
            }

            if (acceptAllCookies && !declineAllCookies) {
                this.setCookieConsentCookie(checkboxElem.value,  true);
            } else if (!acceptAllCookies && declineAllCookies) {
                this.setCookieConsentCookie(checkboxElem.value,  false);
            } else if (!acceptAllCookies && !declineAllCookies) {
                this.setCookieConsentCookie(checkboxElem.value,  checkboxElem.checked);
            }
        })

        this.dispatchEvent(createCustomEvent(COOKIE_BANNER_EVENT_SET_COOKIE_SETTINGS))
    }

    private setCookieConsentCookie(cookieName: string, cookieValue: any) {
        const expiryDate = new Date(),
              path = 'path=/';

        expiryDate.setTime(expiryDate.getTime() + (this.settings.cookieDurationInDays * 24 * 60 * 60 * 1000));

        document.cookie = `${cookieName}=${cookieValue};expires=${expiryDate.toUTCString()};${path};`
    }

    private emitGTMEvent() {
        if (!window.dataLayer || !this.settings.gtmEventName) return
        window.dataLayer.push({'event': this.settings.gtmEventName})
    }

    public toggleCookieBanner(showHide: boolean) {
        this.dom.banner.classList.toggle(CookieBanner.States.BannerActive, showHide);
    }

    private onButtonSubmitAllClick() {
        if (this.editmode) return

        this.setCookieSettings(true);
        this.updateCheckboxCheckedStateByRequiredCookiesValues();
        this.emitGTMEvent();
        this.toggleCookieBanner(false);
    }

    private updateCheckboxCheckedStateByRequiredCookiesValues() {
        const requiredCookiesValues = this.getRequiredCookiesValues();

        if (!requiredCookiesValues) return

        requiredCookiesValues.forEach(requiredCookieValue => {
            let filteredCheckboxElemsArr = this.dom.checkboxElems.filter(checkboxElem => checkboxElem.value === requiredCookieValue.name)

            if (!filteredCheckboxElemsArr.length || !(filteredCheckboxElemsArr[0] instanceof HTMLInputElement)) return

            filteredCheckboxElemsArr[0].checked = requiredCookieValue.value.toLowerCase() === 'true' ? true : false
        });
    }

    private getRequiredCookiesValues() : Array<{name: string, value: string}>|null {
        if (!document.cookie) return null
        if (!this.settings.content || !this.settings.content.cookies || !this.settings.content.cookies.required || !this.settings.content.cookies.required.length) return null

        const requiredCookiesValuesArr : Array<{name: string, value: string}> = [];

        this.settings.content.cookies.required.forEach(cookie => {
            let requiredCookie = this.getCookie(cookie.name);

            if (!requiredCookie) return false

            requiredCookiesValuesArr.push({name: requiredCookie.split('=')[0].trim(), value: requiredCookie.split('=')[1].trim()});
        });

        return requiredCookiesValuesArr;
    }

    private onExternalCookieBannerToggleClick(event: Event) {
        if (!event || !event.target || !(event.target instanceof HTMLElement)) return
        event.preventDefault();

        this.toggleCookieBanner(true);
    }

    private onButtonDeclineAllClick() {
        if (this.editmode) return
        
        this.setCookieSettings(false, true);
        this.updateCheckboxCheckedStateByRequiredCookiesValues();
        this.emitGTMEvent();
        this.toggleCookieBanner(false);
    }

    private onCookieError(error: AxiosResponse|Error) {
        if (window.console && console.error) {
            console.error(error);
        }
    }
}

document.addEventListener('DOMContentLoaded', (evt) => {
    window.AS = window.AS || {};
    const cookieBanner = document.querySelector(CookieBanner.Selectors.Banner);
    if (!(cookieBanner instanceof HTMLElement)) return;
    window.AS.cookieBanner = new CookieBanner(cookieBanner, {content: undefined});
    document.dispatchEvent(
        new CustomEvent(COOKIE_BANNER_EVENT_LOADED_COOKIE_BANNER)
    );
});
